import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as Widget from './Widget';
import ActionCreator from '../ActionCreator';
import * as L from '../Utils/Lang';

class MemberItem extends Component {
  render() {
    let { reverse=false, member, justify=null } = this.props;
    return (
      <Wrapper>
        <Picture>
          <Widget.BgImage image={member.image} />
        </Picture>
        <Column justify={justify} reverse={reverse} extraCss="padding: 10px;flex-grow: 1;" alignItem={'center'}>
          <Title>{L.d(member, 'name')}</Title>
          <Description>{L.d(member, 'title')}</Description>
        </Column>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    width: 60%;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  ${props => props.extraCss};
  order: ${props => (props.reverse ? -1 : 0)};
  align-items: ${props => props.alignItem};

  @media screen and (max-width: 500px) {
    order: 0;
  }
`;
const Picture = styled.div`
  width: 200px;
  height: 200px;
  flex-shrink: 0;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #6b6b6b;
`;
const Description = styled.p`
  color: #6b6b6b;
  max-height: 150px;
  overflow: hidden;
`;

export default connect(
  null,
  ActionCreator
)(MemberItem);
