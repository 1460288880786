import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import MemberItem from '../Components/MemberItem';
import * as L from '../Utils/Lang';
import PathPrefix from '../Utils/PathPrefixUtil';
import Banner from '../Components/Banner';
import * as Widget from '../Components/Widget';

class AboutPage extends React.Component {
  render() {
    let { members } = this.props;
    return (
      <Wrapper>
        <Banner
          image={PathPrefix('/images/pages/aboutUs/banner_ABOUTUS.png')}
          text="ABOUT US"
        />

        <Content>
          <h3>
            <p>{L.s('about-title-1')}</p>
            <p style={{ marginLeft: 10 }}>{L.s('about-title-2')}</p>
          </h3>
          <h4>{L.s('about-subtitle')}</h4>
          <h5>{L.s('about-description')}</h5>
        </Content>
        <div style={{ backgroundColor: '#EFEFEF' }}>
          <AboutItemWrapper>
            <AboutItem>
              <h4>{L.s('about-vision-title')}</h4>
              <h5>{L.s('about-vision-content')}</h5>
            </AboutItem>
            <AboutItem>
              <h4>{L.s('about-culture-title')}</h4>
              <h5>{L.s('about-culture-content')}</h5>
            </AboutItem>
            <AboutItem>
              <h4>{L.s('about-strategy-title')}</h4>
              <h5>{L.s('about-strategy-content')}</h5>
            </AboutItem>
            <div style={{ maxWidth: 900, width: '100%' }}>
              <div className="picture_container">
                <div className="the_picture" />
              </div>
            </div>
          </AboutItemWrapper>
        </div>
        <div>
          <h1
            style={{
              fontWeight: 200,
              textAlign: 'center',
              padding: '20px 0px 10px',
            }}
          >
            OUR TEAM
          </h1>
          <MemberItemWrapper>
            {members.map((member, idx) => (
              <MemberItem member={member} key={idx} />
            ))}
          </MemberItemWrapper>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  line-height: 25px;
  & > h3 {
    font-size: 130%;
    text-align: center;
    color: #442c4c;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    & > p::first-letter {
      font-size: 180%;
      margin-right: 1px;
    }
  }
  & > h4 {
    font-size: 130%;
    color: #565656;
    margin-bottom: 20px;
  }
  & > h5 {
    font-size: 100%;
    color: #6b6b6b;
    white-space: pre-wrap;
    line-height: 30px;
  }

  ${props => props.extraCss};
`;

const AboutItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  padding: 100px;
  
  & .picture_container{
    width: 100%;
    padding-top: 35%; /* the image ratio */
    position: relative;
    & .the_picture{
      background-image: url('/images/pages/aboutUs/company_culture.png');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    @media screen and (max-width: 400px) {
      padding-top: 80%;
      & > .the_picture{
        background-image: url('/images/pages/aboutUs/company_culture_small.png');
      }
    }
  }
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`;

const AboutItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  text-align: center;
  width: 300px;
  line-height: 25px;
  & > h4 {
    color: #565656;
    margin-bottom: 10px;
    border-bottom: 5px solid #442c4c;
  }
  & > h5 {
    color: #6b6b6b;
    white-space: pre-wrap;
    letter-spacing: 2px;
  }

  @media screen and (max-width: 950px) {
    width: 100%;
  }

  ${props => props.extraCss};
`;

const MemberItemWrapper = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  @media screen and (max-width: 880px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      members: Selectors.getMembers(state),
    }),
    ActionCreator
  )(AboutPage)
);
